"use client";

import type NextError from "next/error";
import { useEffect } from "react";
import { sentry } from "@ashgw/observability";
import { Button, toast } from "@ashgw/ui";
export interface GlobalErrorProperties {
  readonly error: NextError & {
    digest?: string;
  };
  readonly reset?: () => void;
}
export const ErrorBoundary = ({
  error,
  reset
}: GlobalErrorProperties) => {
  useEffect(() => {
    toast.message(sentry.next.captureException({
      error
    }));
  }, [error]);
  const handleReset = () => {
    if (reset) {
      reset();
    } else {
      window.location.reload();
    }
  };
  return <div className="dimmed-3 flex h-screen w-full scale-150 flex-col items-center justify-center text-center" style={{
    padding: "40px 20px",
    gap: "20px"
  }} data-sentry-component="ErrorBoundary" data-sentry-source-file="index.tsx">
      <div className="flex flex-col" style={{
      gap: "20px"
    }}>
        <h1 className="text-3xl font-bold">Something went wrong</h1>
        <p className="max-w-md">
          I've logged this error and will look into it as soon as possible.
        </p>
      </div>
      <Button variant="navbar" onClick={handleReset} data-sentry-element="Button" data-sentry-source-file="index.tsx">
        Try again
      </Button>
    </div>;
};