"use client";

import type { PropsWithChildren } from "react";
import { NextUIProvider } from "@nextui-org/react";
import { ThemeProvider as NextThemesProvider } from "next-themes";
import { AnalyticsProvider } from "@ashgw/analytics/provider";
interface ProvidersProps extends PropsWithChildren {
  site: "blog" | "www";
}
export function Providers({
  children,
  site
}: ProvidersProps) {
  return <AnalyticsProvider site={site} data-sentry-element="AnalyticsProvider" data-sentry-component="Providers" data-sentry-source-file="index.tsx">
      <NextThemesProvider attribute="class" defaultTheme="dark" enableSystem disableTransitionOnChange data-sentry-element="NextThemesProvider" data-sentry-source-file="index.tsx">
        <NextUIProvider data-sentry-element="NextUIProvider" data-sentry-source-file="index.tsx">{children}</NextUIProvider>
      </NextThemesProvider>
    </AnalyticsProvider>;
}